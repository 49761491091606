import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Navigate, Routes } from 'react-router-dom';

import AuthPage from './pages/Auth';
import StartPage from './pages/Start';
import UserSignUpPage from './pages/UserSignUp';
import UserVerifyPage from './pages/UserVerify';
import MainNavigation from './components/Navigation/MainNavigation';
import UserAdminPage from './pages/UserAdmin';
import { AuthContext, CheckTokenValid } from './context/auth-context';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import FooterComponent from './components/Footer/footer';
import 'antd/dist/antd.less';
import './components/global.less';
import { BackendAddressGraphQl } from './Settings';

import './App.less';
import PasswordResetPage from './pages/PasswordReset';
import ProjectPage from './pages/Project'; // Test
import ImagePrint from './components/ImagePrint';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SearchListPage from './pages/SearchList';

const client = new ApolloClient({
  uri: BackendAddressGraphQl,
  cache: new InMemoryCache(),
  fetchOptions: {
    mode: 'no-cors',
  }
});

function App(props) {
  const [state, setState] = useState({
    token: null,
    userId: null,
    isAdmin: null
  });


  useEffect(() => {
    if (localStorage.getItem("jwt-Loew")) {
      CheckTokenValid(localStorage.getItem("jwt-Loew")).then(result => {
        console.log(result);
        if (result.valid === false) {
          logout();
        }
        else {
          login(localStorage.getItem("jwt-Loew"), result.userId, result.isAdmin);
        }
      });


    } else {
      logout();
    }
  }, []);

  const login = (token, userId, isAdmin, tokenExpiration) => {
    console.log("login in App called")
    setState({ token: token, userId: userId, isAdmin: isAdmin });
    localStorage.setItem("jwt-Loew", token);
  };

  const logout = () => {
    setState({ token: null, userId: null, isAdmin: null });
    localStorage.removeItem("jwt-Loew");
  };

 
  return (
    <ApolloProvider client={client}>
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter>
          <React.Fragment>
            <AuthContext.Provider
              value={{
                token: state.token,
                userId: state.userId,
                isAdmin: state.isAdmin,
                login: login,
                logout: logout
              }}
            >
              <MainNavigation key={props.key} />
              <div className="before"></div>
              <main className="main-content">
              <Routes>
              {!state.token && 
                <React.Fragment>
                   <Route path="/auth" element={<AuthPage/>} />
                   <Route path="/passwordReset/:token" element={<PasswordResetPage/>} />
                  <Route path="/passwordReset" element={<PasswordResetPage/>} />
                   <Route path="/*" element={<Navigate replace to="/auth"></Navigate>}/>
                </React.Fragment>
                }
                 
                 {state.token && <Route path="/"  element={<Navigate replace to="/start"></Navigate>}/>}
                {state.token && 
                  <Route path="/auth" element={<Navigate replace to="/start"></Navigate>} />
                }
                  
                  <Route path="/auth" element={<AuthPage/>} />
                  {state.userId &&
                  <React.Fragment>
                    <Route path="/start" element={<StartPage/>} />
                    <Route path="/useradministration" element={<UserAdminPage/>} />
                    <Route path="/newProject" element={<SearchListPage newProject={true}></SearchListPage>}/>
                    
                    <Route path="/verify/:token" element={<UserVerifyPage/>} />
                    <Route path="/passwordReset/:token" element={<PasswordResetPage/>} />
                    <Route path="/passwordReset" element={<PasswordResetPage/>} />
                    <Route path="/pdf" element={(props) => <ImagePrint {...props} test="testo" />} />
                  </React.Fragment>
                  }
                  </Routes>
              </main>
              <FooterComponent></FooterComponent>
            </AuthContext.Provider>
          </React.Fragment>
        </BrowserRouter>
      </DndProvider>
    </ApolloProvider>
  );
}


export default App;
