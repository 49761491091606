import { BackendAddressRest } from '../../Settings';
import { useDrag } from "react-dnd";
import { ItemTypes } from "./ItemTypes";
import { Image, Button, Collapse, Divider, Row, Col } from "antd";
import util from 'util';
import { CloseCircleOutlined, PrinterFilled, CheckCircleFilled, CheckCircleOutlined, FullscreenOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';


export const ImageDnd = function ImageDnd({ pictureId, projectSrc, partSrc, fileNameSrc, imageOverlay, style, selectState, pictureSelected, onSelectClick, selectedPictureRef, removeImages }) {
  
   
    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.IMAGE,
        item: { pictureId, selected:selectedPictureRef},
        end: (item, monitor) => {
            
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
                removeImages(dropResult);
              
                console.log(dropResult);
            }
        },
        canDrag: () => {
            return true;
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }));

    useEffect(() =>{
       
    },[selectState, isDragging])

    return (
        <div ref={drag}>
            {selectState === true ?
                <div style={{ width: style.width + "px", height: style.width + "px"}}>

                    <Image style={{ top: "0", left: "0" }}
                        width={style.width}
                        src={`${BackendAddressRest}/thumbnails/${projectSrc._id}/${partSrc._id}/${fileNameSrc}`}
                    />

                    <div style={{ height: style.width + "px", width: style.width + "px", position: 'absolute', justifyContent: "left", top: "0", left: "0" }} onClick={onSelectClick}>
                        {pictureSelected === true ?
                            <CheckCircleFilled className="ClickableIcon ColorYellow" style={{ fontSize: '35px', top: '8px', left: '8px', position: "relative" }} /> :
                            <CheckCircleOutlined className="ClickableIcon ColorYellow" style={{ fontSize: '35px', top: '8px', left: '8px', position: "relative" }} />
                        }
                    </div>
                </div> :

                <Image
                    width={style.width} src={`${BackendAddressRest}/thumbnails/${projectSrc._id}/${partSrc._id}/${fileNameSrc}`}
                    preview={{ src: `${BackendAddressRest}/images/${projectSrc._id}/${partSrc._id}/${fileNameSrc}`, mask: imageOverlay }}
                />

            }

            {isDragging &&
                console.log("dragging, ", selectedPictureRef.current)
            }
        </div>
    );
}