import React, { useContext, useEffect, useRef, useState } from 'react';
import './LocationFilter.css';
import { Divider, Button, Slider, Input, Alert, Row, Col } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { useLazyQuery, gql, useQuery } from '@apollo/client';
import Geocode from "react-geocode";
import CityList from './Lists/Cities/CityList'
import { AuthContext } from '../../context/auth-context';

const { Search } = Input;

Geocode.setApiKey("AIzaSyCYAiJ7AYrY2soiSvwhKgil5rJDIvTF9_U");
Geocode.setLanguage("de");
Geocode.setRegion("de");


const LocationFilter = props => {
  var authContext = useContext(AuthContext);

  const authHeader = { "Authorization": "Bearer " + authContext.token}

  const [searchState, setSearchState] = useState("");

  const [addressErrorStatus, SetaddressErrorStatus] = useState(false);
  const [radiusStatus, SetRadiusStatus] = useState({ actualValue: 20, labelValue: 20 });
  const [locationStatus, SetLocationStatus] = useState("");
  
  const [localityInputVisibleState, setLocalityInputVisibleState] = useState(false);
  const [radiusInputVisibleState, setRadiusInputVisibleState] = useState(false);

  const currentLocationRef = useRef();
  const currentRadiusRef = useRef();
  const currentSelectedCity=useRef();

  const requestBodyCities = gql`
    query Cities{
      cities{
        Name
        ProjectCount
      }
    }
  `
  const { loading: loadingCities, error: errorCities, data: dataCities } = useQuery(requestBodyCities,{context:{headers:authHeader}});

  useEffect(() => {
    if (sessionStorage.getItem("lastSearch-location")) {
      console.log("set session location");
      console.log(sessionStorage.getItem("lastSearch-location"));
      SetLocationStatus(sessionStorage.getItem("lastSearch-location"));
    }
    if (sessionStorage.getItem("lastSearch-radius")) {
      inputChangedHandler("radius", sessionStorage.getItem("lastSearch-radius"));
    }
  }, []);

  //setzt die refs auf die Werte der Inputfelder
  const inputChangedHandler = async (field, e) => {
    switch (field) {
      case "location":
        SetLocationStatus(e.target.value);
        break;
      case "radius":
        if (e === 101) {
          console.log(e);
          SetRadiusStatus({ actualValue: 1000, labelValue: "max" });
        }
        else {
          currentRadiusRef.current = e;
          SetRadiusStatus({ actualValue: e, labelValue: e });
        }
        break;
      case "radiusInput":
        // if(e.target.value.length<2)
        // {
        //   SetradiusStatus({actualValue:20,labelValue:20});
        // }
        if (e.target.value >= 101) {
          console.log(e.target.value);
          SetRadiusStatus({ actualValue: 1000, labelValue: "max" });
        }
        // else if(e.target.value<20)
        // {
        //   currentRadiusRef.current = 20;
        //   SetradiusStatus({actualValue:20,labelValue:20});
        // }
        else {
          currentRadiusRef.current = e.target.value;
          SetRadiusStatus({ actualValue: e.target.value, labelValue: e.target.value });
        }
        break;
      case "radiusInputBlur":
        if (e.target.value < 20) {
          currentRadiusRef.current = 20;
          SetRadiusStatus({ actualValue: 20, labelValue: 20 });
        }
        break;
      default:
        break;
    }
  };

  const enterHandler = async (field, e) => {
    switch (field) {
      case "location":
        setFiltersHandler();
        break;
      case "radiusInput":
        await inputChangedHandler("radiusInputBlur", e).then(setFiltersHandler());
        break;
      default:
        break;
    }
  };

  function formatter(value) {
    if (value === 101) {
      return "max"
    }
    return `${value} km`;
  }

  const marks = {
    20: {
      label: "20",
    },
    40: {
      label: "40",
    },
    60: {
      label: "60",
    },
    80: {
      label: "80",
    },
    101: {
      label: "max",
    }
  };
  //nimmt den Input und macht einen call an die google maps API um die GPS Koordinaten des Zielortes zu bekommen und leitet 
  //diese an den Parent zurück
  const setFiltersHandler = async () => {
    props.OnPanelOpened(props.Ckey);

    let location = locationStatus;
    sessionStorage.setItem("lastSearch-location", location);
    var retObject = { ...props.FilterObject.current };
    delete retObject.text;
    retObject.locality = currentSelectedCity.current;
    if (location && (location.length > 0 || location !== "")) {
      console.log("in Geocode");
      await Geocode.fromAddress(location).catch(err => { console.log(err) }).then(
        response => {
          if (response) {
            currentLocationRef.current = response.results[0].address_components[0].long_name;
            const { lat, lng } = response.results[0].geometry.location;
            retObject.loc={};
            retObject.loc.lat = lat.toString();
            retObject.loc.lng = lng.toString();

            SetaddressErrorStatus(false);
          }
          else {
            SetaddressErrorStatus(true);
          }
        },
        error => {
          console.error(error);
        }
      );
    }
    var radius = 20;
    if (radiusStatus.actualValue > 20) {
      radius = radiusStatus.actualValue;
    }
    if(retObject.radius)
    {
      sessionStorage.setItem("lastSearch-radius", radius);
    }
    if(retObject.loc)
    {
      sessionStorage.setItem("lastSearch-coordinates", JSON.stringify(retObject.loc));
    }
   
    retObject.radius = (radius * 1000);
    await props.OnChangeFilterObject(retObject);
  };


  const onCitySelect = async (city) =>
  {
    currentSelectedCity.current = city;
    setFiltersHandler();

  }

  const textSearchHandler = async(e)=>{
    setSearchState(e);
    console.log(e);
    var retObject = {};
    retObject.text=e;
    retObject.status = "OPEN"
    props.OnPanelOpened(props.Ckey);
    await props.OnChangeFilterObject(retObject)
  }

  return (
    <div style={{ display: "contents", display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%", height: "100%" }}>
      <div className="project-search-header">
        <Search defaultValue={searchState} onSearch={(e)=>{textSearchHandler(e)}} placeholder="Suche" enterButton />
      </div>
      <h2 className="titletext-h2-standard"> Städte</h2>
      {dataCities &&
        <CityList cities={dataCities.cities} onCitySelected={onCitySelect} style={{height:"100%"}}></CityList>
      }
      {/* </Form> */}
    </div>
  );
}

export default LocationFilter