import React, { useEffect, useRef, useState } from 'react';
import './ProjectPartFilter.css';
import { Divider, Button, Spin, Input } from 'antd';
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import ProjectPartList from './Lists/Projects/Parts/ProjectPartList';
const { Search } = Input;

const ProjectPartFilter = props => {


  const [searchState, setSearchState] = useState("");
  const [previewState, setPreviewState] = useState(props.FilterObject.current);
  let prevState;

  
  useEffect(() => {

    props.OnPassFilterState(setPreviewState);
    console.log(previewState);
  }, [previewState]);


  const updateSearchState = (e) => {
    setSearchState(e);
  }


  const filterParts = (parts) => {
    if (searchState.length === 0) {
      return parts;
    }
    return parts.filter(part => {
      if (
        part.name.toLowerCase().includes(searchState.toLowerCase())
      ) {
        return true;
      }
      return false;
    });
    ;
  }

  const onPartselected = async (part) => {
    await props.OnPanelOpened(props.Ckey);
    var filterObject = { ...previewState };
    filterObject.part = part;
    await props.onChangeFilterObject(filterObject);
  }


  const OnPartsLoaded = async (parts)=>{
    // var filterObject = { ...projectState };
    // filterObject.project._parts = parts;
    // await props.onChangeFilterObject(filterObject);
  }

  return (
    <div className="projectPart-filter-content">
      <div className="projectPart-search-header">
        <Search defaultValue={searchState} onSearch={updateSearchState.bind(null)} placeholder="Suche" enterButton />
      </div>
      {previewState && previewState.previewProject && previewState.previewProject._parts &&
        <div className="projectPart-scroll-list">
          <p className="smalltext-standard-lightgray" style={{ opacity: "0.5", margin: "auto" }}>{previewState.previewProject._parts.length} {previewState.previewProject._parts.length == 1 ? "Abschnitt" : "Abschnitte"} gefunden in {previewState.previewProject.name}</p>
          <ProjectPartList selectedProject={previewState.previewProject} onPassPartsLoaded={OnPartsLoaded} onClickPart={onPartselected}></ProjectPartList>
        </div>
      }
    </div>
  );
}
export default ProjectPartFilter