import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import mapStyles from './mapstyles';
import React, { useState, useEffect, useRef } from 'react';
//import EmployerItem from './../Employers/EmployerList/EmployerItem/EmployerItemMapPopup';


const MapContainer = props => {

  const [employersState, setEmployersState] = useState();
  const [centerState, setCenterState] = useState();
  const [selectedEmployerState, setSelectedEmployerState] = useState();
  const [selectedMarkerState, setSelectedMarkerState] = useState();
  const [infoVisibleState, setInfoVisibleState] = useState()

  useEffect(() => {
    props.PassStateSetCenterToParent(setCenterState);
    props.PassStateSetEmployersToParent(setEmployersState);
    if (centerState === undefined) {
      console.log(props.MapStateRef.current);
      setCenterState(props.MapStateRef.current.center);
    }
    if (employersState === undefined) {
      setEmployersState(props.MapStateRef.current.employers);
    }
  }, [centerState, employersState]);

  const displayMarkers = () => {
    return employersState.map((employer, index) => {
      return (
        <Marker key={index} id={index} position={{
          lat: employer._address.loc.latitude,
          lng: employer._address.loc.longitude
        }}
          onClick={(props, marker) => {
            setSelectedEmployerState(employer); setSelectedMarkerState(marker); setInfoVisibleState(true);
          }} >
        </Marker>
      )
    })
  }

  const displayInfoWindow = () => {
    if (selectedEmployerState && selectedMarkerState) {
      return (
        <InfoWindow marker={selectedMarkerState} visible={infoVisibleState} options={{ maxWidth: 1000 }}>
          {/* <EmployerItem
        key={selectedEmployerState._id}
        name={selectedEmployerState.name}
        street={selectedEmployerState._address.street}
        number={selectedEmployerState._address.number}
        zipCode={selectedEmployerState._address.zipCode}
        city={selectedEmployerState._address.city}
        departments={selectedEmployerState._departments}
        /> */}

        </InfoWindow>
      );
    }
    return (<InfoWindow visible={false} options={{ maxWidth: 900 }}><div></div></InfoWindow>);
  }


  const mainContent = (
    <React.Fragment>
      {centerState &&
        <Map
          google={props.google}
          zoom={11}

          containerStyle={{ position: 'relative', width: '100%', height: '100%' }}
          initialCenter={centerState}
          center={centerState}
        >

          {employersState && displayMarkers()}
          {displayInfoWindow()}
        </Map>
      }
    </React.Fragment>);

  return mainContent;
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCYAiJ7AYrY2soiSvwhKgil5rJDIvTF9_U',
  language: "de"
})(MapContainer);

