import React, { useState, useEffect, useRef } from 'react';

import { Map, GoogleApiWrapper } from 'google-maps-react';
import { AuthContext } from '../context/auth-context';

import MapsWrapper from '../components/Maps/MapsWrapper';
import { useLazyQuery, gql } from '@apollo/client';
import Geocode from "react-geocode";
import { Input, Row, Col, Divider, Slider, Button, Spin, Alert } from 'antd'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import ProjectPage from './Project';

import './SearchList.css';
import '../components/global.less'
import SidePanel from '../components/SidePanel/SidePanel.js';

const { Search } = Input;

Geocode.setApiKey("AIzaSyCYAiJ7AYrY2soiSvwhKgil5rJDIvTF9_U");
Geocode.setLanguage("de");
Geocode.setRegion("de");

const SearchListPage = props => {

  let isActive = true;
  const context = AuthContext;

  
  const _mapStateRef = useRef({center:{lat:"50.218881", lng:"8.6200112829727"}, employers:[]});
  const _mapStateSetFuncProjectRef = useRef((project)=>{});
  const _mapStateSetCenterRef = useRef((center)=>{});
  const [testState, setTestState] = useState();
  const [isNewProjectState, setisNewProjectState] = useState(props.newProject);


  useEffect(() => {
    console.log("useffect: ");   
  }, []);
  
  //wird vom SidePanel aufgerufen und leitet die GPS-Location vom SidePanel->LocationFilter an die Karte weiter
  const onReceiveLocation = async (loc)=>
  {
    console.log(loc);
    _mapStateSetCenterRef.current({lat:loc.latitude, lng:loc.longitude});
    _mapStateRef.current={..._mapStateRef,center:{lat:loc.latitude, lng:loc.longitude}};
  }
  
  //wird vom SidePanel aufgerufen und leitet die gefundenen Arbeitgeber vom SidePanel->EmployerFilter an die Karte weiter
  const onReceiveProject = async (project)=>{
    console.log(project);
    setisNewProjectState(false);
    setTestState(project);
  };

  //wird vom MapsWrapper aufgerufen und leitet die Funktion zum ändern des States an den Parent um Änderungen vom Parent aus zu initialisieren
  const onReceiveMapStateSetFuncCenter = async (setMapState)=>{
    console.log(setMapState);
    _mapStateSetCenterRef.current = setMapState;
  };
  //wird vom MapsWrapper aufgerufen und leitet die Funktion zum ändern des States an den Parent um Änderungen vom Parent aus zu initialisieren
  const onReceiveMapStateSetFuncEmployers = async (setMapState)=>{
    console.log(setMapState);
    //_mapStateSetFuncEmployersRef.current = setMapState;
  };

const emptyProject = {
  name:"",
  _address:
  {
    throughfare:"",
    locality:"",
    postal_code:"",    
  },
  _parts:[{
    name:"1. Abschnitt",
  }]
}

const deleteProjectHandler =async ()=>{
  setTestState(undefined);
}

const mainContent = (
  <React.Fragment>
      <span className="searchPage-container">
        <span className="sidePanel-wrapper">
          <SidePanel OnPassLocationToParent={onReceiveLocation} PassProjectToParent={onReceiveProject}></SidePanel>
        </span>
        <div className="employerMap">
          {/* <MapsWrapper MapStateRef={_mapStateRef} PassStateSetEmployersToParent={onReceiveMapStateSetFuncEmployers} PassStateSetCenterToParent={onReceiveMapStateSetFuncCenter}></MapsWrapper> */}
         {isNewProjectState ? 
         
         <ProjectPage selectedProject={emptyProject} editMode={true} deleteProject={deleteProjectHandler} isNewProject={isNewProjectState}></ProjectPage>
         :testState &&
          <ProjectPage selectedProject={testState} editMode={false} deleteProject={deleteProjectHandler}></ProjectPage>
         }
        
        </div>
      </span>
  </React.Fragment>);
  

  return mainContent;
}

export default SearchListPage;
