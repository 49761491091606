import React, { useEffect, useRef, useState, useContext } from 'react';
import './ProjectFilter.css';
import { Divider, Button, Spin, Input, Tabs } from 'antd';
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import {UnorderedListOutlined, UserOutlined} from '@ant-design/icons';
import ProjectList from './Lists/Projects/ProjectList';
import { AuthContext } from './../../context/auth-context';

const { Search } = Input;
const { TabPane } = Tabs;

const ProjectFilter = props => {


  const [searchState, setSearchState] = useState("");
  const [projectState, setProjectState] = useState();
  const filterObjctRef = useRef();
  const userProjectsOnlyRef = useRef(false);
  let prevState;

  const {token, userId} = useContext(AuthContext);
  const authHeader = {context: {
    headers: {
        Authorization: 'Bearer ' + token
    }
}};
 
  const requestBody = gql`
  query ProjectConnection($locationSearchInput:LocationSearchInput!,$textSearchInput:TextSearchInput!, $status:String!, $userProjectsOnly: Boolean!){
    projectConnection(locationSearchInput:$locationSearchInput,textSearchInput:$textSearchInput, status: $status, userProjectsOnly: $userProjectsOnly) {
      _projects {
        _id
        name
        profilePic
        status
        _metaData {
          creationDate
                  
        }
        _address {
          throughfare
          locality
          postal_code
          loc {
            lng
            lat
          }
        }
        _parts {
          _id
          name
          }
      }
    }
  }
`;

  const onSearchResults = async (project) => {
    // console.log(projectState);
    // var retObject = {...projectState};
    // retObject.project = project;
    // await props.OnChangeFilterObject(retObject);
    //setSearchState("");
  };

  const [getprojects, { loading, error, data }] = useLazyQuery(requestBody, authHeader);

  useEffect(() => {

    props.OnPassFilterState(setProjectState);
    console.log("effect ProjectList");
    console.log(props.FilterObject.current);
    if(!projectState)
    {
      setProjectState(props.FilterObject.current);
    }
    if(!filterObjctRef.current)
    {
      filterObjctRef.current = props.FilterObject.current;
    }
    if(props.FilterObject.current)
    {
      getprojects( {
            variables: {
              locationSearchInput:{
                addressInput:{
                  locality:props.FilterObject.current.locality,
                },
                radius:props.FilterObject.current.radius,
                loc:props.FilterObject.current.loc
              },
              textSearchInput:{
                text:props.FilterObject.current.text,
              },
              status:props.FilterObject.current.status,
              userProjectsOnly: userProjectsOnlyRef.current,
              }
          });
    }
    // if (prevState !== projectState) {
    //   filterObjctRef.current  = projectState
    //   getprojects( {
    //     variables: {
    //       locationSearchInput:{
    //         addressInput:{
    //           locality:filterObjctRef.current.locality,
    //         },
    //         radius:filterObjctRef.current.radius,
    //         loc:filterObjctRef.current.loc
    //       },
    //       status:filterObjctRef.current.status,
    //       userProjectsOnly: userProjectsOnlyRef.current,
    //       }
    //   });
    //   console.log("state differs");
    //   console.log(filterObjctRef.current);
    // }
    // if (data) {
    //   onSearchResults(data.projects);
    // }
    // prevState = projectState;
  }, [projectState,data,props.FilterObject.current]);


  const updateSearchState = (e) => {
    setSearchState(e);
  }


  const filterProjects = (projects) => {
    if (searchState.length === 0) {
      return projects;
    }
    console.log("in Filter");
    return projects.filter(project => {
      if (
        project._address.locality.toLowerCase().includes(searchState.toLowerCase()) ||
        project.name.toLowerCase().includes(searchState.toLowerCase()) ||
        project._address.throughfare.toLowerCase().includes(searchState.toLowerCase()) ||
        project._address.postal_code.toLowerCase().includes(searchState.toLowerCase())
      ) {
        return true;
      }
      return false;
    });    
  }

  const onProjectselected = async (project) => {
    await props.OnPanelOpened(props.Ckey);
    var filterObject = {...filterObjctRef.current, previewProject:project};
    filterObject.project = project;
    console.log(filterObject);
    filterObjctRef.current =filterObject;
    await props.OnChangeFilterObject(filterObject);
    props.panelClose();
  }

  const onProjectPreviewSelected = async (project) => {
    await props.OnPanelOpened(props.Ckey);
    var filterObject = {...filterObjctRef.current, previewProject:project};
    console.log(filterObject);
    filterObjctRef.current =filterObject;
    await props.OnChangeFilterObject(filterObject);
  }

  const onTabChangedHandler =async (key)=>
  {
    switch(key)
    {
      case "all":
        userProjectsOnlyRef.current = false;
        
      break;
      case "user":
      userProjectsOnlyRef.current = true;
      break;
      default:
        userProjectsOnlyRef.current = false;
        break;
    }

    getprojects( {
      variables: {
        locationSearchInput:{
          addressInput:{
            locality:filterObjctRef.current.locality,
          },          
          radius:filterObjctRef.current.radius,
          loc:filterObjctRef.current.loc
        },
        textSearchInput:{
          text:props.FilterObject.text,
        },
        status:filterObjctRef.current.status,
        userProjectsOnly: userProjectsOnlyRef.current,
        }
    });
  }

  return (
    <div className="project-filter-content">
      <div className="project-search-header">
        <Search defaultValue={searchState} onSearch={updateSearchState.bind(null)} placeholder="Suche" enterButton />
      </div>
      <Tabs defaultActiveKey="1" onChange={(key)=>onTabChangedHandler(key)} style={{height:"100%", display:"flex",}}>
    <TabPane style={{  height:"calc(100vh - 220px"}}
      tab={
        <span>
         <UnorderedListOutlined />
          Alle
        </span>
      }
      key="all"
    >
      {loading ? (
        <div className="list-spinner-container">
          <Spin size="large" />
        </div>) :
        data &&
        <div className="project-scroll-list">
          <p className="smalltext-standard-lightgray" style={{ opacity: "0.5", margin: "auto" }}>{data.projectConnection._projects.length} {data.projectConnection._projects.length == 1 ? "Projekt" : "Projekte"} gefunden</p>
          <ProjectList projects={filterProjects(data.projectConnection._projects)} onClickPreview={onProjectPreviewSelected} onClickProject={onProjectselected}></ProjectList>
        </div>
      }
    </TabPane>
    <TabPane style={{height:"inherit"}}
      tab={
        <span>
          <UserOutlined />
          Meine
        </span>
      }
      key="user"
    >
      {loading ? (
        <div className="list-spinner-container">
          <Spin size="large" />
        </div>) :
        data &&
        <div className="project-scroll-list">
          <p className="smalltext-standard-lightgray" style={{ opacity: "0.5", margin: "auto" }}>{data.projectConnection._projects.length} {data.projectConnection._projects.length == 1 ? "Projekt" : "Projekte"} gefunden</p>
          <ProjectList projects={filterProjects(data.projectConnection._projects)} onClickPreview={onProjectPreviewSelected} onClickProject={onProjectselected}></ProjectList>
        </div>
      }
    </TabPane>
  </Tabs>
     
    </div>
  );
}
export default ProjectFilter