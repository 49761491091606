import React, { useState, useRef, useEffect } from 'react';

import Modal from '../components/Modal/Modal';
import Backdrop from '../components/Backdrop/Backdrop';
import {AuthContext} from '../context/auth-context';
import SocialBannerComponent from './../components/social/SocialBanner';
import JoinBannerComponent from './../components/social/JoinBanner';
import SearchPage from './SearchList';
import './Start.css';
import './../components/global.css'
import Geocode from "react-geocode";
import { Row, Col, Divider, Image, Card, List, Avatar, Comment, Button, Input, Form as FormA, Select } from 'antd';
import 'antd/dist/antd.less';

const { Option } = Select;
Geocode.setApiKey("AIzaSyCYAiJ7AYrY2soiSvwhKgil5rJDIvTF9_U");
Geocode.setLanguage("de");
Geocode.setRegion("de");


const StartPage = props=>{


    return (
      <div className="pageContainer" >
        <SearchPage/>
        </div>
    );
  
}

export default StartPage;
