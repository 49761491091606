import { BackendAddressRest } from '../../Settings';
import { useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes";
import Scrollbar from 'react-smooth-scrollbar';
import { Image, Button, Collapse, Divider, Row, Col, Spin } from "antd";
import { FullscreenOutlined } from '@ant-design/icons';
import util from 'util';
import { useState, useEffect, useRef } from 'react';
import { render } from 'react-dom';


export const PartListTile = ({ part, project }) => {
    const [movedImages, setMovedImages] = useState();
    const imgRef = useRef();
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: ItemTypes.IMAGE,
        drop: (x) => {
            imgRef.current = [...x.selected.current];
            return { target: { projectId: project._id, partId: part._id }, sources: imgRef.current };
        },
        collect: (monitor) => {
            return {
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            };

        },

    }));
    const isActive = canDrop && isOver;
    let backgroundColor = '';
    if (isActive) {
        backgroundColor = "#ffcc00";
    }
    else if (canDrop) {
        backgroundColor = "#ffe991";
    }

    useEffect(() => {
        if (!movedImages) {
            setMovedImages([])
        }
        if (!imgRef.current) {
            imgRef.current = [];
        }

    }, [movedImages]);

    const imageOverlay = () => {
        return (
            <div style={{ height: '60px', width: '60px', flexDirection: 'column', display: 'flex' }}>
                <Row justify="center" align="middle" style={{ height: '100%' }}>
                    <Col>
                        <FullscreenOutlined style={{ fontSize: '25px', width: '100%', marginLeft: 'auto', marginRight: 'auto' }} />
                    </Col>
                </Row>
            </div>
        )
    }


    return (
        <div ref={drop} className="projectPartList-projectItem" style={{ backgroundColor }}>
            <p className="smalltext-bold-standard">{part.name}</p>
            <Scrollbar className="custom-scroll-window" direction="X" alwaysShowTracks={true} style={{ width: "100%" }}>
                <Row style={{width: `${Math.round(Math.max(part._pictures.filter(x => x.deleted === false).length/ 2,5)) * 66}px` }}>
                    {part._pictures.map(picture => {
                        if (picture.deleted === false) {
                            return (
                                <Col style={{ margin: "3px" }}>
                                    <Image
                                        style={{ width: "60px", height: "60px" }}
                                        placeholder={<Spin />}
                                        src={`${BackendAddressRest}/thumbnails/${project._id}/${part._id}/${picture.fileName}`}
                                        preview={{ src: `${BackendAddressRest}/images/${project._id}/${part._id}/${picture.fileName}`, mask: imageOverlay(picture) }}
                                    />
                                </Col>
                            );
                        }
                    })}

                </Row>
                {imgRef.current && imgRef.current.length > 0 &&
                    <Row style={{ width: `${Math.round(Math.max(imgRef.current.length / 2,5)) * 66}px` }}>
                        {imgRef.current.map(picture => {
                            return (
                                <Col style={{ margin: "3px" }}>
                                    <Image
                                        style={{ width: "60px", height: "60px" }}
                                        placeholder={<Spin />}
                                        src={`${BackendAddressRest}/thumbnails/${picture.projectId}/${picture.partId}/${picture.fileName}`}
                                        preview={{ src: `${BackendAddressRest}/images/${picture.projectId}/${picture.partId}/${picture.fileName}`, mask: imageOverlay(picture) }}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                }
            </Scrollbar>
        </div>
    );
};

