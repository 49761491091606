import React, { useEffect, useRef  } from 'react';
import './SidePanel.css';
import { Divider, Checkbox, Col, Row,Icon } from 'antd';

import LocationFilter from './LocationFilter';
import ProjectFilter from  './ProjectFilter';
import ProjectPartFilter from  './ProjectPartFilter';
import { MenuUnfoldOutlined, MenuFoldOutlined, BorderHorizontalOutlined, LeftOutlined, RightOutlined, SwapOutlined } from '@ant-design/icons';
import {ReactComponent as Drag} from './../../resources/drag.svg'

const SidePanel = props => {

  let _mouseDown=false;
  let xStart;
  let panelWidthinPx;
//Indikator, wie viele der Filterpanels schon geöffnet sind
let _openedPanel = Math.max(localStorage.getItem("loew-openedPanel"),1);

//initiale Werte für die Filter (GPS:mitte BW, radius 1000km, keine Kategorieeinschränkung)
let filterObjectRef = useRef();

const _setProjectFilterStateRef = useRef();
const _setProjectPartFilterStateRef = useRef();
let _selectedEmployer={};

//gibt den Index eines Filterpanels zurück +1, da 1-4
const getColumnIndex = (column) =>{
  return columns.findIndex(item => item.name === column.name) +1;
}

//berechnet die Position eines Filterpanels auf basis der zahl geöffneter Panels
const calcLeftPos = (column) =>{
  var rightestPlace = 100 *_openedPanel -100;
  var targetPos = (100 * (getColumnIndex(column)-1));
  if(rightestPlace > targetPos)
  {
    return `${targetPos}%`;
  }
  else{
    return `${rightestPlace}%`
  }
}

const calcZindex =(column)=>{
  var colIndex = getColumnIndex(column);
  return parseInt(10/colIndex);
}

//wird von den Filterpanels aufgerufen und setzt die -openedPanels Variable auf den index des aufrufenden Panels +1
const onPanelOpened = async(panelName)=>
{
  console.log("onPanelOpened")
  console.log(panelName);
  var index = columns.findIndex(item => item.name === panelName);
  if(_openedPanel<index+2)
  {
    _openedPanel = index+2;
    localStorage.setItem("openedPanel",Math.min(_openedPanel,3));
    console.log(_openedPanel)
  }
  await window.requestAnimationFrame(async ()=>{
    columns.forEach(element => {
      let index = getColumnIndex(element);
      if(index === _openedPanel){
        document.getElementById(element.name).classList.add("shadowElement");
      }
      else
      {
        document.getElementById(element.name).classList.remove("shadowElement");
      }
      document.getElementById(element.name).style.left = calcLeftPos(element);
      document.getElementById("drag").style.left = (_openedPanel * 100) -1 +"%";
    });
  });

};

//setzt die Position der Panels mittels CSS syling
const movePanel = async(column, type) =>
{

 if(type ==="fold")
 {
   document.getElementById("sidePanel-container").style.left = `${-100 * getColumnIndex(column)}%`;
 }
 if(type ==="unfold")
 {
  document.getElementById("sidePanel-container").style.left = `${ -100 *getColumnIndex(column) + 200}%`;
 }
};

const movePanelClick = async (e)=>{
  document.getElementById("sidePanel-container").style.transition = 'left .5s ease-in-out';
  console.log("click sidepanel");
  console.log(_openedPanel);
  await window.requestAnimationFrame(async ()=>{
  if(document.getElementById("sidePanel-container").style.left === "0%")
  {
    document.getElementById("sidePanel-container").style.left = `${ -100 *localStorage.getItem("openedPanel") +100}%`;
  }
  else{
    document.getElementById("sidePanel-container").style.left = "0%";
  }
});
}

// const movePanelmouseDown = (e) =>
// {
//   e.preventDefault();
//   document.getElementById("sidePanel-container").style.transition = '';
//   _mouseDown = true;
//   xStart = e.clientX - document.getElementById("sidePanel-container").offsetLeft;
//   panelWidthinPx = document.getElementById("sidePanel-container").clientWidth;
//   console.log(panelWidthinPx);
//   document.getElementById("root").addEventListener('mouseup',movePanelMouseUp, true);
//   document.getElementById("root").addEventListener('mousemove',movePanelMouseMove, true);
// }


// function movePanelMouseUp(e){
//   e.preventDefault()
//   document.getElementById("sidePanel-container").style.transition = 'left .5s ease-in-out';
//   let percentageOffset = document.getElementById("sidePanel-container").offsetLeft/panelWidthinPx *100;
//   document.getElementById("root").removeEventListener('mousemove', movePanelMouseMove, true);
//   document.getElementById("root").removeEventListener('mouseup', movePanelMouseUp, true);
//   console.log(document.getElementById("sidePanel-container").offsetLeft)
//   console.log(panelWidthinPx);
//   console.log(percentageOffset);
//   window.requestAnimationFrame(()=>{
//     if(percentageOffset>-50)
//     {
//       document.getElementById("sidePanel-container").style.left = '0%';
//     }
//     if(percentageOffset<-51 && percentageOffset>-150)
//     {
//       document.getElementById("sidePanel-container").style.left = '-100%';
//     }
//     if(percentageOffset<-151 && percentageOffset>-250)
//     {
//       document.getElementById("sidePanel-container").style.left = '-200%';
//     }
//     if(percentageOffset<-251)
//     {
//       document.getElementById("sidePanel-container").style.left = '-300%';
//     }
//   });
//   _mouseDown = false;
// }


// function movePanelMouseMove(e){
//   if(_mouseDown ===true)
//   {
//     window.requestAnimationFrame(()=>{
//       document.getElementById("sidePanel-container").style.left = e.clientX -xStart+'px';
//     });
//   }
// }

//wird von dem Panel "Projekte"(ProjectsFilter) und reicht sein state objekt nach oben, um die Filterwerte aus dem SidePanel(Parent) zu ändern
const onPassFilterStateProject = (filterStateSetFunc)=>
{
  _setProjectFilterStateRef.current = filterStateSetFunc;  
};
//wird von dem Panel "Projekte"(ProjectsFilter) und reicht sein state objekt nach oben, um die Filterwerte aus dem SidePanel(Parent) zu ändern
const onPassFilterStateProjectPart = (filterStateSetFunc)=>
{
  _setProjectPartFilterStateRef.current = filterStateSetFunc;  
};

//wird vom Panel "LocationFilter" aufgerufen und reicht die geänderten örtlichen Filter nach oben
const onChangeFilterObjectLocation = async (filterObject)=>
{
  console.log("city changed");
  console.log(filterObject);
  filterObjectRef.current = filterObject;
  _setProjectFilterStateRef.current(filterObject);
};

const onChangeFilterObjectProject = async (filterObject)=>
{
  console.log(filterObject);
  filterObjectRef.current.project = filterObject.project;
  _setProjectPartFilterStateRef.current(filterObject);
  await  props.PassProjectToParent(filterObject.project);
}

const animateSidepanelClose = async ()=>{
  setTimeout(() => {
    window.requestAnimationFrame(async ()=>{
      document.getElementById("sidePanel-container").style.left = '-200%';});
  }, 500);
}

const onChangeFilterObjectProjectPart = async (filterObject)=>
{
  console.log(filterObject);
  filterObjectRef.current.part = filterObject.part;
  await  props.PassProjectToParent(filterObject.project);
  
}




useEffect(() => {
  console.log(filterObjectRef.current);
  if(!filterObjectRef.current)
  {
    filterObjectRef.current = {
      status:"OPEN",
  userProjectsOnly:false} ;
  
if(sessionStorage.getItem("lastSearch-coordinates"))
{
  filterObjectRef.current.loc = JSON.parse(sessionStorage.getItem("lastSearch-coordinates"));
}
if(sessionStorage.getItem("lastSearch-radius"))
{
  filterObjectRef.current.radius= (sessionStorage.getItem("lastSearch-radius") * 1000).toString();
}
if(sessionStorage.getItem("lastSearch-selectedCategories"))
{
  filterObjectRef.current.depCategoryIds=JSON.parse(sessionStorage.getItem("lastSearch-selectedCategories")).map(category =>{return category._id});
}
  }
  
 
 },[]);
 

 const columns =[
  {text:"Umgebung", name:"location", code:<LocationFilter Ckey="location" FilterObject={filterObjectRef} OnChangeFilterObject={onChangeFilterObjectLocation} OnPanelOpened={onPanelOpened}></LocationFilter>},
  {text:"Projekte", name:"projects", code:<ProjectFilter Ckey="projects" FilterObject={filterObjectRef} OnChangeFilterObject={onChangeFilterObjectProject}  OnPassFilterState={onPassFilterStateProject}  OnPanelOpened={onPanelOpened} panelClose={animateSidepanelClose}></ProjectFilter>},
  {text:"Abschnitte", name:"parts", code:<ProjectPartFilter Ckey="parts"  FilterObject={filterObjectRef} OnChangeFilterObject={onChangeFilterObjectProjectPart}  OnPassFilterState={onPassFilterStateProjectPart}  OnPanelOpened={onPanelOpened}></ProjectPartFilter>}
];

  const createFilterColumns = (columns) =>{
    return columns.map(column => {
      return (
        <Row id={column.name} key={column.name} className="sidePanel-filterRow" style={{"width":`100%`, height:'100%', position:'absolute', zIndex:`${calcZindex(column)}`,backgroundColor:'white', left:`${calcLeftPos(column)}`}}>
          <Col  span={(24)} className="filter-column" style={{height:"100%", paddingLeft:"8px",paddingRight:"8px"}}>
            <Row style={{height:"40px"}} align="middle" justify="center">
              <Col span={(19)} >
                <Row justify="center"> <h1 className="titletext-h2">{column.text}</h1></Row>               
              </Col>              
            </Row>
            <Row style={{height:"calc(100% - 40px)"}}>
              {column.code}
            </Row>           
          </Col>
        </Row>
      );
    });
  }

  return (
    <React.Fragment>
      <div id="sidePanel-container" className="sidePanel-container" style={{"width":"100%"}}>
          {createFilterColumns(columns)}
          <Row type="flex" id="drag" className="sidePanel-filterRow" justify="start" style={{position:'absolute',top:"calc(0%)", left:`calc(${_openedPanel} * 100% - 1px)`, height:"60px", width:"50px", alignItems:"start", zIndex:"999"}}>
            <Drag className="geometry" style={{position:"absolute", top:"0px"}}>             
            </Drag>
            <SwapOutlined className="ClickableIcon" style={{fontSize:'30px', zIndex:"9999", marginTop:"8px"}} onClick={()=>{movePanelClick()}}></SwapOutlined>
          </Row>
      </div>
    </React.Fragment>
  )

};

export default SidePanel;
