import { useEffect, useContext } from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import { Image, Row, Col, Spin, Divider } from "antd";
import './ProjectPartList.less'
import { BackendAddressRest } from './../../../../../Settings.js';
import { PartListTile } from '../../../../DragDrop/PartListTile';
import ScrollingLock from 'react-scrolling-lock';
import { AuthContext } from '../../../../../context/auth-context';

const ProjectPartList = props => {

    const { token, userId } = useContext(AuthContext);

    const authHeader = {context: {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }};

    const requestBody = gql`
    query Project($id:String!){
      project(id:$id) {      
          _id
          profilePic
          _parts {
              _id
            name
            _pictures{
              fileName
              deleted
            }
            }
        }
      }  
  `;

    const { loading, data, error } = useQuery(requestBody, {...authHeader, variables: { id: props.selectedProject._id } });


    useEffect(() => {
        console.log("listrender");
        if (data && data.project && data.project._parts) {
            props.onPassPartsLoaded(data.project._parts)
        }

    }, [props.parts, data]);

    const ComposedList = ScrollingLock()(()=>{

        return(
            <div className="custom-scroll-window" alwaysShowTracks={false} style={{width:"19vw",overflow:"auto", height:"calc(100vh - 260px)"}}>
            {data && data.project._parts.map(part => {
                return (
                    <div>
                        <PartListTile
                        part={part}
                        project={data.project}
                        ></PartListTile>
                        <Divider style={{ margin: "4px" }}></Divider>
                    </div>
                );
            })}
        </div >
        );

    })

    return (
     <ComposedList></ComposedList>
    );
};

export default ProjectPartList;