import React, { useEffect, useState, useContext } from 'react';
import '../components/global.less';
import './Project.css';
import { Modal, Row, Col, Switch, Divider, Image, Collapse, Card, List, Avatar, Comment, Form, Input, DatePicker, Button } from 'antd';
import { useQuery, gql, useLazyQuery, useMutation } from '@apollo/client';
import { BackendAddressRest } from '../Settings';
import { CloseCircleOutlined, ExclamationCircleOutlined, DeleteOutlined, CloseOutlined, CheckOutlined, EditOutlined, FullscreenOutlined } from '@ant-design/icons';
import ImageGrid from '../components/CustomCollapse/ImageGrid';
import { AuthContext } from '../context/auth-context';

const { Panel } = Collapse;
const { TextArea } = Input;
const { confirm } = Modal;

const ProjectPage = (props) => {

    const [editState, setEditState] = useState(props.editMode);
    const [projectState, setProjectState] = useState();
    const [projectEditState, setProjectEditState] = useState();

    const { token, userId } = useContext(AuthContext);

    const authHeader = {context: {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }};

    const requestBody = gql`
    query Project($id:String){
      project(id:$id) {      
          _id
          profilePic
          name
          _parts {
              _id
            name
            _pictures{
                _id
              fileName
              creationDate
              modifiedDate
              description
              deleted
            }
            }
            _address {
            _id
            throughfare
            locality
            postal_code
            loc {
              lng
              lat
            }
          }
        }
      }  
  `;

    const [requestProject,{ loading, data, error }] = useLazyQuery(requestBody, authHeader);

    const mutationBody = gql`
    mutation ProjectUpdate($projectInput: ProjectInput) {
        updateProject(projectInput: $projectInput) {
          _id
          name
          profilePic
          status
          _metaData {
            _owner {
              _id
              email
              username
              isAdmin
            }
            _creationUser {
              _id
              email
              username
              isAdmin
            }
            creationDate
          }
          _address {
            _id
            throughfare
            locality
            postal_code
            loc {
              lng
              lat
            }
          }
          _parts {
            _id
            name
            _pictures {
                _id
              fileName
              creationDate
              modifiedDate
              description
              deleted
            }
          }
        }
      }
    `
    const deleteProjectBody = gql`
 mutation DeleteProject($projectId: ID) {
     deleteProject(projectId: $projectId)
     }
 `

    const [updateMutation, { loading: loadingMutation, data: dataMutation, error: errorMutation }] = useMutation(mutationBody, authHeader);
    const [deleteProjectMutation, { loading: loadingDeleteMutation, data: dataDeleteMutation, error: errorDeleteMutation }] = useMutation(deleteProjectBody, {context: { headers: authHeader }});

    const onEditClickHandler = async => {
        setEditState(true);
    };

    const onCheckClickHandler = () => {
        setEditState(false);
        updateProject();
    };
    const onCloseClickHandler = async => {
        setEditState(false);
    };

    const updateProject = () => {
        console.log(projectEditState);
        updateMutation({
            variables: {
                "projectInput": {
                    "projectId": projectEditState._id,
                    "name": projectEditState.name,
                    "description": projectEditState.description,
                    "profilePic": projectEditState.profilePic,
                    "status": projectEditState.status,
                    "_parts": projectEditState._parts.map(part => {
                        return { partId: part._id, name: part.name }
                    }),
                    "_address": {
                        addressId: projectEditState._address._id,
                        throughfare: projectEditState._address.throughfare,
                        postal_code: projectEditState._address.postal_code,
                        locality: projectEditState._address.locality,
                        // loc:{lat:projectEditState._address.loc.lat, lng:projectEditState._address.loc.lng},
                    }
                }
            }
        });
    }

    const inputChangeHandler = (key, value, e) => {
        switch (key) {
            case "projectName":
                console.log(value);
                setProjectEditState({ ...projectEditState, name: value });
                break;
            case "throughfare":
                setProjectEditState({ ...projectEditState, _address: { ...projectEditState._address, throughfare: value } });
                break;
            case "postal_code":
                setProjectEditState({ ...projectEditState, _address: { ...projectEditState._address, postal_code: value } });
                break;
            case "locality":
                setProjectEditState({ ...projectEditState, _address: { ...projectEditState._address, locality: value } });
                break;
            case "description":
                setProjectEditState({ ...projectEditState, description: value });
                break;
            case "part":
                var parts = projectEditState._parts.map(part => {
                    console.log(value);
                    if (part._id === value) {
                        return { ...part, name: e.target.value };
                    }
                    else {
                        return part;
                    }
                });
                console.log(parts);
                setProjectEditState({ ...projectEditState, _parts: parts });
                break;
            case "newPart":
                var newParts = [...projectEditState._parts, { name: "neuer Abschnitt" }];
                setProjectEditState({ ...projectEditState, _parts: newParts });
                break;
            default:

                break;
        }
        console.log(projectEditState);
    };

    const deleteProjectHandler = async () => {
        confirm({
            title: `Soll das Projekt ${projectState.name} wirklich gelöscht werden?`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Ja',
            okType: 'danger',
            cancelText: 'Nein',
            onOk: async () => {
                var res = await deleteProjectMutation({ variables: { projectId: projectState._id } });
                if(!res.errorrs)
                {
                    props.deleteProject();
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });

    }


    useEffect(() => {
        console.log("useEffect"); 
        console.log({token:token});       
        if(!props.isNewProjectState){
           requestProject({variables:{id:props.selectedProject._id}});          
        }
        if (!dataMutation && data) {
            console.log(data.project);
            setProjectState({ ...data.project });
            setProjectEditState({ ...data.project });
        }
        else if (dataMutation) {
            setProjectState({ ...dataMutation.updateProject });
            setProjectEditState({ ...dataMutation.updateProject });
        }
        if(!projectState)
        {
            setProjectState({...props.selectedProject});
        }
        if(!projectEditState)
        {
            setProjectEditState({...props.selectedProject});
        }
       
    }, [props.selectedProject, dataMutation, data]);

    const imageOverlayProfilePic = (picture) => {
        return (
            <div style={{ height: '100%', width: '100%', flexDirection: 'column', display: 'flex', justifyContent: "center", borderRadius: "500px" }}>
                <FullscreenOutlined style={{ fontSize: '35px', width: '100%', marginLeft: 'auto', marginRight: 'auto' }} />
                <p className="titletext-h2-standard" style={{ color: 'white', margin: "0", marginLeft: "auto", marginRight: "auto" }}>Vorschau</p>
            </div>
        )
    };

    return (
        <React.Fragment>
            {projectState &&
                <div className="container-project" style={{ marginBottom: "3rem", marginTop: "2rem", marginLeft: "32px" }}>
                    <div >
                        {editState === false ?
                            <Row >
                                <Col>
                                    <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', height: "100%" }}>
                                        <p className="titletext-h1-standard" style={{ display: 'inline-block', verticalAlign: 'middle', marginBottom: "0" }}>{projectState.name}</p>
                                    </div>
                                </Col>
                                <Col flex="auto">

                                </Col>
                                <Col>
                                    <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', height: "100%" }}>
                                        <EditOutlined className="ClickableIcon" style={{ fontSize: '24px', display: 'inline-block', verticalAlign: 'middle', marginRight: "16px" }} onClick={onEditClickHandler}></EditOutlined>
                                        <DeleteOutlined className="ClickableIcon" style={{ fontSize: '24px', display: 'inline-block', verticalAlign: 'middle', marginRight: "16px" }} onClick={deleteProjectHandler}></DeleteOutlined>
                                    </div>
                                </Col>
                            </Row> :
                            <Row>
                                <Col flex="1 0 90%">
                                    <Input type="text" id="projectName" size='large' defaultValue={`${projectEditState.name}`} placeholder='Projektname' onBlur={(event) => inputChangeHandler("projectName", event.target.value)}></Input>
                                </Col>
                                <Col flex="auto">
                                </Col>
                                <Col>
                                    <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', height: "100%" }}>
                                        <CloseOutlined className="ClickableIcon" style={{ fontSize: '24px' }} onClick={onCloseClickHandler}></CloseOutlined>
                                        <CheckOutlined className="ClickableIcon" style={{ fontSize: '24px' }} onClick={onCheckClickHandler}></CheckOutlined>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </div>

                    {/* //////////////////Adresse///////////////////// */}
                    <Row justify="start">
                        <Col className="DataColumn" style={{ verticalAlign: 'baseline' }} span={12}>
                            <Row className="DataRow">
                                <Col style={{ width: '100%' }}>
                                    <Divider className='titletext-h2' orientation='left'>Adresse</Divider>
                                    {editState === true ?
                                        projectEditState &&
                                        <div>
                                            <Input type="text" style={{ width: '100%', marginBottom: "4px" }} id="street" placeholder='Straße + Nr.' defaultValue={projectEditState._address.throughfare} onBlur={(event) => inputChangeHandler("throughfare", event.target.value)}></Input>
                                            <Row>
                                                <Input type="text" style={{ width: '30%' }} id="street" placeholder='Plz' defaultValue={projectEditState._address.postal_code} onBlur={(event) => inputChangeHandler("postal_code", event.target.value)}></Input>
                                                <Input type="text" style={{ width: '70%' }} id="street" placeholder='Ort' defaultValue={projectEditState._address.locality} onBlur={(event) => inputChangeHandler("locality", event.target.value)}></Input>
                                            </Row>
                                        </div> :
                                        projectState && projectState._address &&
                                        <div>
                                            <p className="smalltext-standard" justify="start" >{projectState._address.throughfare}</p>
                                            <Row>
                                                <p className="smalltext-standard" justify="start" >{projectState._address.postal_code} {projectState._address.locality}</p>
                                            </Row>
                                        </div>
                                    }
                                </Col>
                            </Row>
                            <Row className="DataRow">
                                <Col style={{ width: '100%' }}>
                                    <Divider className='titletext-h2' orientation='left'>Beschreibung</Divider>
                                    {editState === true ?
                                        <TextArea rows={6} className="TableElement" type="textarea" id="description" defaultValue={projectEditState.description} onBlur={(event) => inputChangeHandler("description", event.target.value)}></TextArea> :
                                        <p className="smalltext-standard" justify="start" >{projectState.description}</p>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col className="DataColumn" span={12}>
                            <Row className="DataRow" justify="center">
                                {projectState && projectState._id && projectState.profilePic &&
                                    <Image style={{ borderRadius: "500px" }}
                                        width={370} src={`${BackendAddressRest}/profilePic/thumbnails/${projectState._id}/${projectState.profilePic}`}
                                        // preview={false}
                                        preview={{ src: `${BackendAddressRest}/profilePic/images/${projectState._id}/${projectState.profilePic}`, maskClassName: "roundedImagePreviewMask", mask: imageOverlayProfilePic() }}
                                    />
                                }
                            </Row>
                        </Col>
                    </Row>
                    <Row>

                    </Row>
                    {projectState && projectState._parts && projectState._id &&
                        <Row className="DataGrid">
                            <ImageGrid project={projectState} editState={editState} inputChangedHandler={inputChangeHandler} style={{ width: "200px", height: "200px" }}></ImageGrid>
                        </Row>
                    }
                </div>}
        </React.Fragment >
    );
};

export default ProjectPage;