import { useEffect } from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import './ProjectList.less'
import { RightOutlined } from '@ant-design/icons';
import { Col, Divider, Row } from 'antd';
import ScrollingLock from 'react-scrolling-lock';

const ProjectList = props => {

    useEffect(() => {
        console.log("listrender");
    }, [props.projects]);


    const clickedHandler = (project) => {
        props.onClickProject(project);
    }


    const clickedOpenPartsHandler = (project) => {
        props.onClickPreview(project)
    }

    const ComposedList = ScrollingLock()(()=>{

        return(
        <div className="custom-scroll-window" alwaysShowTracks={false}  style={{width:"19vw",overflow:"auto", height:"calc(100vh - 294px)"}}>
            {props.projects.slice().sort((a, b) => a.name.localeCompare(b.name)).map(project => {
                return (
                    <div>
                    <Row className='projectList-project'>
                        {/*select and open project*/}
                        <Col span={20} className="projectList-projectItem" style={{ display: 'inline-flex' }} onClick={clickedHandler.bind(null, project)}>
                            <p className="smalltext-bold-standard">{project.name}</p>
                        </Col>
                        {/*open partsList of project*/}
                        <Col span={4} className="projectList-projectItem" onClick={clickedOpenPartsHandler.bind(null, project)} style={{ heigth: "100%", display: 'inline-flex', justifyContent: "center", alignItems: "center" }} >
                            <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', height: "100%" }}>
                                <RightOutlined style={{ fontSize: "15px", alignSelf: "center" }} />
                            </div>
                        </Col>
                    </Row>
                    <Divider  style={{margin:"4px"}}></Divider>
                    </div>
                );
            })}
        </div>
        )
    });

    return (
      <ComposedList></ComposedList>
    );
};

export default ProjectList; 