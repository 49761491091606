import React, { useState, useEffect, useRef, useContext } from 'react';

import { Map, GoogleApiWrapper } from 'google-maps-react';
import { AuthContext } from '../context/auth-context';

import MapsWrapper from '../components/Maps/MapsWrapper';
import { useMutation, gql, useQuery } from '@apollo/client';
import Geocode from "react-geocode";
import { Input, Row, Col, Divider, Table, Button, Checkbox, Modal } from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';

import '../components/global.less'

const { Search } = Input;

Geocode.setApiKey("AIzaSyCYAiJ7AYrY2soiSvwhKgil5rJDIvTF9_U");
Geocode.setLanguage("de");
Geocode.setRegion("de");

const UserAdminPage = props => {

  
  let isActive = true;
  const {token, userId} = useContext(AuthContext);
  
  const editUserRef = useRef({});
  const [backdropVisibleState, setBackdropVisibleState] = useState(false);
  const [modalDeleteVisibleState, setModalDeleteVisiblaState] = useState(false);
  
  const authHeader = {context: {
    headers: {
        Authorization: 'Bearer ' + token
    }
}};

    const requestUsersBody = gql`
    query Users{
      users {      
        _id
        email
        username
        isAdmin
        }
      }  
  `;

  const updateUserMutation = gql`
  mutation UpdateUser($username:String!, $email:String!, $isAdmin:Boolean!, $password:String, $userId:ID){
    updateUser(userInput:{username:$username, email:$email, isAdmin:$isAdmin, password:$password, userId:$userId}) {      
      _id
      email
      username
      isAdmin
      }
    }  
`;

const deleteUserMutation = gql`
mutation DeleteUser($userId:ID){
  deleteUser(userId:$userId) 
  }  
`;

  const {data, loading, error, refetch} = useQuery(requestUsersBody,authHeader)
  
  const [updateUser,{loading:loadingUpdateUser,error: errorUpdateUser, data:dataUpdateUser}] = useMutation(updateUserMutation, authHeader);
  const [deleteUser,{loading:loadingDeleteUser,error: errorDeleteUser, data:dataDeleteUser}] = useMutation(deleteUserMutation, authHeader);

  useEffect(() => { 
  }, [data, dataDeleteUser]);
 
  const editUserHandler = async(user)=>{
      console.log(user);
    editUserRef.current= {};
    editUserRef.current.userId= user._id;
    editUserRef.current.username = user.username;
    editUserRef.current.email = user.email;
    editUserRef.current.isAdmin = user.isAdmin;
    setBackdropVisibleState(true);
  };

  const editValuesChanged = async(key, value)=>
  {
      switch(key)
      {
        case "username":
            editUserRef.current.username= value;
            break;
        case "email":
            editUserRef.current.email= value;
            break;
        case "isAdmin":
            editUserRef.current.isAdmin= value;
            break;
        case "password":
            if(value.trim().length >0)
            editUserRef.current.password = value;
            break;
        default:
            break;
      }
  }

  const deleteUserHandler = async(user)=>{
    editUserRef.current= {};
    editUserRef.current.userId= user._id;
    editUserRef.current.username = user.username;
    editUserRef.current.email = user.email;
    editUserRef.current.isAdmin = user.isAdmin;
    setModalDeleteVisiblaState(true);
    
  };
  const deleteUserRequestHandler = async ()=>
  {
    deleteUser({variables:{userId:editUserRef.current.userId}});
    refetch();
    setModalDeleteVisiblaState(false);
  }
  const saveUserHandler = ()=>{
    updateUser({variables:editUserRef.current})
    setBackdropVisibleState(false);
  }


const userColumns=[
    {
        title:"Benutzername",
        dataIndex:"username",
        key:"username"
    },
    {
        title:"E-Mail",
        dataIndex:"email",
        key:"email"
    },
    {
        title:"Admin",
        dataIndex:"isAdmin",
        key:"isAdmin",
        render:(record)=>{
            if(record)
            {
            return (<p>Admin</p>)
            }
        }
    },
    {
        title:"Bearbeiten",
        key:"action",
        render: (record) => (
            <div>
            <Button onClick={editUserHandler.bind(null,record)}>
              <EditOutlined ></EditOutlined>
            </Button>
            <Button onClick={deleteUserHandler.bind(null,record)}>
              <DeleteOutlined ></DeleteOutlined>
            </Button>
          </div>
          ),
    }
]

const mainContent = (
  <React.Fragment>
      <Button onClick={()=>editUserHandler({})}><PlusOutlined></PlusOutlined> Neuer Benutzer</Button>
     {data && 
     <React.Fragment>
         <Table columns={userColumns} dataSource={data.users} style={{minHeight:"80vh"}} pagination={{ pageSize: 999 }}></Table>
    </React.Fragment>}
    {backdropVisibleState &&
    <Modal visible={backdropVisibleState} onCancel={()=>setBackdropVisibleState(false)} onOk={()=>saveUserHandler()}>
        <Divider orientation='left'>Benutzername</Divider>
        <Input placeholder='Benutzername' defaultValue={editUserRef.current.username} onBlur={(event)=>editValuesChanged("username", event.target.value)}></Input>
        <Divider orientation='left'>E-Mail</Divider>
        <Input placeholder='E-Mail' defaultValue={editUserRef.current.email} onBlur={(event)=>editValuesChanged("email", event.target.value)}></Input>
        <Divider orientation='left'>Admin</Divider>
        <Checkbox  defaultChecked={editUserRef.current.isAdmin} onBlur={(event)=>editValuesChanged("isAdmin", event.target.checked)}></Checkbox>
        <Divider orientation='left'>Passwort</Divider>
        <Input.Password  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} placeholder='Neues Passwort' onBlur={(event)=>editValuesChanged("password", event.target.value)}></Input.Password>
    </Modal>
    }   
      {modalDeleteVisibleState &&
    <Modal visible={modalDeleteVisibleState} onCancel={()=>setModalDeleteVisiblaState(false)} onOk={()=>deleteUserRequestHandler()}>
        <h2>Soll der Benutzer wirklich gelöscht werden?</h2>
        <Divider orientation='left'>Benutzername</Divider>
        <p>{editUserRef.current.username}</p>
        <Divider orientation='left'>E-Mail</Divider>
        <p>{editUserRef.current.email}</p>
        <Divider orientation='left'>Admin</Divider>
        <Checkbox  defaultChecked={editUserRef.current.isAdmin} disabled={true}></Checkbox>
    </Modal>
    }   
  </React.Fragment>);
  

  return mainContent;
}

export default UserAdminPage;
