import React, { useState, useEffect } from "react";
import { Collapse, Divider, Row, Col, Input, Icon } from "antd";
import useSelection from "antd/lib/table/hooks/useSelection";
import { RightOutlined, DownOutlined } from '@ant-design/icons';
import './CustomCollapse.less';
import 'antd/dist/antd.less';

const { Panel } = Collapse

const CustomCollapse = (props) => {

    const [collapseState, setCollapseState] = useState({});

    const onCollapse = (key) => {
        console.log(key);
        collapseState[key] = !collapseState[key];
        setCollapseState({...collapseState});
    }

    

    useEffect(() => { 
        console.log(props.children);
        if(collapseState[0] ===undefined)
        {
            for(var child in props.children)
            {
                collapseState[child] = false;
            }
            setCollapseState({...collapseState});
        }
        console.log(collapseState);
    }, [collapseState, props.children]);

    
    return (
        <Collapse style={{ marginBlockEnd: '100px', width: '100%' }} defaultActiveKey={['0']} ghost
            bordered={false}
            onChange={onCollapse.bind(null)}
            expandIcon={({isActive})=><RightOutlined rotate={isActive ? 90:0} style={{ fontSize: "30px", alignSelf: "center",}}></RightOutlined>}
        >
            {props.children.map((child, index) => {
                return (
                    <Panel key={index} showArrow={true}            
                            header = {(
                            <div style={{width:"100%"}}>
                                {props.editState ? 
                                <Divider orientation="left" style={{ width: "90%" }}>
                                    <Input type="text" onClick={(e)=>{e.stopPropagation()}} id="projectName" size='large' defaultValue={`${child.name}`} placeholder='Abschnitt Name' onBlur={(e)=>{props.inputChangedHandler("part", child._id, e);props.setNameChange(child._id, e);}}>
                                    </Input>
                                </Divider>:
                                <Divider orientation="left" style={{ width: "90%" }} ><div className="titletext-h1-standard">{child.name}</div></Divider>
                                }
                                </div>)}
                    > 
                        {child.code}
                    </Panel>
                )
            })}

        </Collapse>
    )
}

export default CustomCollapse;