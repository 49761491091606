import React, { useState, useEffect } from "react";
import 'antd/dist/antd.less';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image, BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import { BackendAddressRest } from "../Settings";
import { PrinterFilled } from '@ant-design/icons';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        padding: "2cm",
    },
    section: {
        flexGrow: 1
    }
});


const ImagePrint = (props) => {

    const ImageDocument = (props) => (
        <Document title={"Druckansicht - " + props.project.name}>
            <Page size="A4" style={styles.page} >
                <View style={styles.section}>
                    <Text>{props.project.name}</Text>
                    {props.selectedPictures.map(picture => {
                        return(
                            <View wrap ={false}>
                            <Text>{picture.creationDate}</Text>
                            <Image src={`${BackendAddressRest}/images/${props.project._id}/${picture.partId}/${picture.fileName}`}></Image> 
                            </View>
                        )
                    })}
                </View>
            </Page>
        </Document>
    );

    return (
        <PDFDownloadLink className="Button" fileName={props.project.name} style={{}} document={<ImageDocument project={props.project} selectedPictures={props.selectedPictures} />}>
            {({ url }) => (
                <p href={url} target="_blank" style={{marginTop:"auto", marginBottom:"auto"}}>
                    <PrinterFilled /> Drucken
                </p>
            )}
        </PDFDownloadLink>
    )
}

export default ImagePrint;