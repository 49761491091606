import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { AuthContext } from '../../context/auth-context';
import AuthPage from '../../pages/Auth';
import './MainNavigation.less';
import logo from '../../resources/logo150px.png';
import { Menu, Icon, Row, Col } from 'antd';


const MainNavigation = props => {
  const { SubMenu } = Menu;



  const { token, logout, isAdmin, userId } = useContext(AuthContext);

  const [selectedMenu, selectedMenuChanged] = useState(props.key);

  const menuSelectHandler = (e) => {
    selectedMenuChanged(e.key);
  }

  return (
    <header className="main-navigation">
      <div className="main-navigation__wrapper">
        <Row className="main-navigation__menu">
          <Col spa={6} style={{ marginTop: "auto", marginBottom: "auto" }}>
            <a href="/start">
              <img src={logo} style={{ marginRight: "16px" }}></img>
            </a>
          </Col>
          <Col flex="auto">
            <Row justify="end" align="middle" className="nav-row">

              <nav className="main-navigation__items">
                <ul>
                  {!token && (
                    <li>
                      <NavLink to="/auth">Login</NavLink>
                    </li>
                  )}
                  {token && (
                    <React.Fragment>
                      <li>
                        <NavLink to="./Start">Start</NavLink>
                      </li>
                      {isAdmin
                      }
                      <li>
                        <button onClick={logout}>Logout</button>
                      </li>

                    </React.Fragment>
                  )}
                </ul>
              </nav>

            </Row>
            {userId &&
            <Row justify="end">
              <Col span={24}>
                <Menu style={{ width: "100%", borderColor:"#434f4f", WebkitTapHighlightColor:"#999999", backgroundColor:"#ffcc00" }} theme="light" mode="horizontal" onSelect={menuSelectHandler.bind(null)} selectedKeys={selectedMenu}>
                  <Menu.Item key="start"><NavLink className="smalltext-standard" to="./start">Start</NavLink> </Menu.Item>
                  <Menu.Item key="newProject"><NavLink className="smalltext-standard" to="./newProject">Neues Projekt</NavLink> </Menu.Item>
                  {/* <Menu.Item key="faq"><NavLink className="smalltext-standard" to="./faq">FAQ</NavLink></Menu.Item> */}
                  {isAdmin &&
                  <Menu.Item key="userAdministration"><NavLink className="smalltext-standard" to="./useradministration">Benutzerverwaltung</NavLink></Menu.Item>
                  }
                  </Menu>
              </Col>
            </Row>
}
          </Col>
        </Row>

      </div>
    </header>
  );
}


export default MainNavigation;
