import React, { useState, useRef, useEffect, useContext } from "react";
import { Image, Button, Collapse, Divider, Row, Col, Modal } from "antd";
import { useQuery, gql, useLazyQuery, useMutation } from '@apollo/client';
import useSelection from "antd/lib/table/hooks/useSelection";
import { CloseCircleOutlined, DeleteOutlined, PlusOutlined, CheckCircleOutlined, FullscreenOutlined } from '@ant-design/icons';
import 'antd/dist/antd.less';
import { BackendAddressRest } from '../../Settings';
import Animate from 'rc-animate';
import QueueAnim from 'rc-queue-anim';
import CustomCollapse from './CustomCollapse';
import ImagePrint from '../ImagePrint';
import { Link } from "react-router-dom";
import { ImageDnd } from "../DragDrop/ImageDnD";
import moment from "moment";
import { AuthContext } from "../../context/auth-context";
import ImageGallery from "./ImageGallery"

const ImageGrid = (props) => {
    const { token, userId, isAdmin } = useContext(AuthContext);

    const [selectState, setSelectState] = useState(false);
    const [selectedPicturesState, setSelectedPicturesState] = useState([]);
    const [renderState, setRenderSate] = useState(false);
    const [deleteImagesModalState, setDeleteImagesModalState] = useState(false);
    const partsRef = useRef([]);

    const selectedPicturesRef = useRef();

    const authHeader = {
        context: {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
    };


    const requestBody = gql`
    query Project($id:String!){
      project(id:$id) {      
          _id
          name
           _address{
                throughfare
                postal_code
                locality
            }
          _parts {
              _id
            name
           
            _pictures{
               _id
              fileName
              creationDate
              modifiedDate
              description
              deleted
            }
            }
        }
      }  
  `;

    const moveImagesRequestBody = gql`
  mutation MoveImages($moveInput:[PictureMoveInput!]!){
    moveImages(moveInput:$moveInput) 
    }  
`;

    const deleteImagesRequestBody = gql`
  mutation DeletePictures($deleteInput:PicturesDeleteInput!){
    deletePictures(deleteInput:$deleteInput) 
    }  
`;

    const [moveImagesMutation, { loading: loadingMutation, data: dataMutation, error: errorMutation }] = useMutation(moveImagesRequestBody, authHeader);
    const [deleteImagesMutation, { loading: loadingDelete, data: dataDelete, error: errorDelete }] = useMutation(deleteImagesRequestBody, authHeader);

    const { loading, data, error, refetch } = useQuery(requestBody, { variables: { id: props.project._id }, context: { ...authHeader.context }, fetchPolicy: 'no-cache', });

    const onSelectClickHandler = async (picture) => {
        console.log(picture)
        picture.selected = !picture.selected;
        if (picture.selected === true) {
            setSelectedPicturesState(oldArray => [...oldArray, picture]);
            selectedPicturesRef.current.push(picture);
        }
        else {
            setSelectedPicturesState(oldArray => [...oldArray.filter(x => x._id != picture._id)]);
            selectedPicturesRef.current = selectedPicturesRef.current.filter(x => x._id != picture._id);
        }
        console.log(selectedPicturesState.length);
        if (selectedPicturesState.length > 0 || picture.selected === true) {
            setSelectState(true);
        }
        if (picture.selected === false && selectedPicturesState.length === 1 && picture._id === selectedPicturesState[0]._id) {
            setSelectState(false);
        }
        console.log(selectedPicturesRef.current);
    };

    const onDeselectClickHandler = async () => {
        setSelectedPicturesState([]);
        selectedPicturesRef.current = [];
        for await (const part of partsRef.current) {
            for await (const img of part._pictures) {
                img.selected = false;
            }
        }
        setSelectState(false);
    }

    const onDeleteClickHandler = async () => {

        console.log("Delete ", selectedPicturesState)
        var result = await deleteImagesMutation({
            variables: {
                "deleteInput": {
                    pictures: selectedPicturesRef.current.map(image => {
                        return {
                            pictureId: image._id,
                            partId: image.partId,
                            projectId: image.projectId
                        }
                    }),
                    projectId: props.project._id,
                }
            },
        });
        if (result.data.deletePictures === true) {
            console.log("in result true")
            await refetch();
            selectedPicturesRef.current = [];
            setDeleteImagesModalState(false);

        }
    }

    const triggerRefreshProject = async () => {
        await refetch();
    }


    const moveImagesDnD = (dropResult) => {
        if (dropResult.sources) {
            for (let i = 0; i < dropResult.sources.length; i++) {
                var srcPart = partsRef.current.find(x => x._id === dropResult.sources[i].partId);
                srcPart._pictures = srcPart._pictures.filter(x => x._id !== dropResult.sources[i]._id)
                let srcPartIndex = partsRef.current.findIndex(x => x._id === dropResult.sources[i].partId);
                partsRef.current[srcPartIndex] = srcPart;
            }
            moveImagesMutation({
                variables: {
                    "moveInput": dropResult.sources.map(image => {
                        return {
                            sourceProjectId: image.projectId,
                            sourcePartId: image.partId,
                            pictureId: image._id,
                            targetProjectId: dropResult.target.projectId,
                            targetPartId: dropResult.target.partId
                        }
                    }),
                }
            });
            setRenderSate(!renderState);
        }

    }

    const setNameChange = async (childId, e) => {
        console.log("name change");

        partsRef.current = partsRef.current.map(part => {
            if (part._id === childId) {
                return { ...part, name: e.target.value };
            }
            else {
                return part;
            }
        });
    }

    const newPartHandler = () => {
        partsRef.current.push({ name: "neuer Abschnitt" });
        props.inputChangedHandler("newPart");
    }

    useEffect(() => {
        console.log(props.project);
        if (data) {
            console.log("data effekt reload");
            partsRef.current = [];
            data.project._parts.forEach(part => {
                partsRef.current.push({
                    _pictures: part._pictures.map((picture) => {
                        return { _id: picture._id, fileName: picture.fileName, creationDate: moment(picture.creationDate).format("DD.MM.YYYY"), selected: false, partId: part._id, projectId: data.project._id, deleted: picture.deleted }
                    }), name: part.name, _id: part._id,
                })
            });
            console.log(data.project._parts);
            console.log(partsRef.current);
            setRenderSate(!renderState);

        }
        if (!selectedPicturesRef.current) {
            selectedPicturesRef.current = [];
        }
    }, [data, props.project]);







    const createChildren = (parts) => {

        const children = [];
        for (const part of parts.current) {
            children.push({
                name: part.name,
                _id: part._id,
                code: <ImageGallery project={props.project} part={part} width={props.style.width} height={props.style.height} selectState={selectState} selectedPicturesRef={selectedPicturesRef} onSelectClickHandler={onSelectClickHandler} moveImagesDnD={moveImagesDnD} triggerRefreshProject={triggerRefreshProject}></ImageGallery>
            })
        }
        return children;
    }
    return (
        <React.Fragment>
            {props.editState &&
                <Button onClick={newPartHandler.bind(null)}><PlusOutlined></PlusOutlined> neuer Abschnitt</Button>
            }
            {data && partsRef.current &&
                <React.Fragment>
                    <CustomCollapse editState={props.editState} setNameChange={setNameChange} children={createChildren(partsRef)} inputChangedHandler={props.inputChangedHandler}>
                    </CustomCollapse>
                    {selectState &&
                        <QueueAnim duration={30} interval={50} type={"right"}>
                            <Button key={"a"} className='yellow-button shadowElement-360' style={{ position: "fixed", right: "32px", bottom: "32px", width: "160px", height: "64px", fontSize: "20px", borderRadius: "100px" }}><ImagePrint selectedPictures={selectedPicturesRef.current} project={props.project}></ImagePrint></Button>
                            <Button key={"b"} className='yellow-button shadowElement-360' onClick={() => { setDeleteImagesModalState(true) }} style={{ position: "fixed", right: "32px", bottom: "112px", width: "160px", height: "64px", fontSize: "20px", borderRadius: "100px" }}><DeleteOutlined /> Löschen</Button>
                            <Button key={"c"} className='yellow-button shadowElement-360' onClick={onDeselectClickHandler} style={{ position: "fixed", right: "32px", bottom: "192px", width: "160px", height: "64px", fontSize: "20px", borderRadius: "100px" }}><CloseCircleOutlined /> Aufheben</Button>
                        </QueueAnim>
                    }
                    <Modal title="Bilder Löschen?" cancelText="Abbruch" open={deleteImagesModalState} onOk={onDeleteClickHandler} onCancel={() => { setDeleteImagesModalState(false) }}>
                        <p className="smalltext-standard">Sollen {selectedPicturesRef.current.length} Bilder gelöscht werden?</p>
                    </Modal>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default ImageGrid;