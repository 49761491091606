import React, { useState, useRef, useEffect, useContext } from "react";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend'
import { Image, Button, Collapse, Divider, Row, Col, Progress } from "antd";
import { CloseCircleOutlined, DownloadOutlined, CheckCircleOutlined, FullscreenOutlined } from '@ant-design/icons';
import  {LoadingBar}  from 'react-loading-bar';
import { ImageDnd } from "../DragDrop/ImageDnD";
import { ItemTypes } from "../DragDrop/ItemTypes";
import { BackendAddressRest } from "../../Settings";
import axios from 'axios';

const ImageGallery = ({project, part, selectState, width, height, selectedPicturesRef, onSelectClickHandler, moveImagesDnD, triggerRefreshProject}) =>{

    const [imagesDropped, setImagesDropped] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [uploading, setUploading] = useState(false);

    const instance = axios.create();


    const [{ canDrop, isOver }, drop] = useDrop({
        accept: NativeTypes.FILE,
        drop: async (item) => {
          const files = item.files;
          const newImages = [...imagesDropped];
    
          for (let i = 0; i < files.length; i++)
          {
                if(files[i].type.match("image.*")) {
                    //handle image file
                    const file = files[i];
                    newImages.push(file);
                }
            }
            setImagesDropped(newImages);
            
            return 
          },
          collect: (monitor) => {
            return {
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            };
        },
    });
      
    const isActive = canDrop && isOver;
    let backgroundColor = '';
    if (isActive) {
        backgroundColor = "#ffcc00";
    }
    else if (canDrop) {
        backgroundColor = "#ffe991";
    }

    const removeAllDroppedImages = async() =>{
        setImagesDropped([]);
    }

    const uploadAllDroppedImages = async()=>{
        
        setUploading(true);
        for (let i = 0; i < imagesDropped.length; i++)
        {
            const file= imagesDropped[i];
            // create a new FormData object
            const formData = new FormData();
            // append the file to the form data object
            formData.append("file", file);
            formData.append("id", file.name);

            setUploadProgress((prevProgress)=>({...prevProgress, [file.name]:0}));
            try {
                const config = {
                    onUploadProgress: (progressEvent) => {
                      
                      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                      console.log("set progress: "+percentCompleted);
                      
                      setUploadProgress((prevProgress)=>({
                        ...prevProgress,
                        [file.name]: percentCompleted,
                      }));
                      console.log(uploadProgress);
                    }
                };
                
               await instance.post(`${BackendAddressRest}/upload/${project._id}/${part._id}`, formData, config);
                
            } catch (err) {
                console.log(err);
            }
        }
        
        setUploading(false);
        setImagesDropped([]);
        triggerRefreshProject();
    }

    const imageOverlay = (picture) => {
        return (
            <div style={{ height: '100%', width: '100%', flexDirection: 'column', display: 'flex' }}>
                {selectState === false &&
                    <span style={{ height: '100%' }}>
                        <Row justify="center" align="bottom" style={{ height: '100%', width:"100%", position:"absolute" }}>
                            <Col>
                                <p className="smalltext-standard" style={{ color: 'white', margin: "0" }}>{picture.creationDate}</p>
                            </Col>
                        </Row>
                        <div style={{ position: 'absolute' }} onClick={(e) => e.stopPropagation()}>
                            <CheckCircleOutlined className="ClickableIcon ColorWhite" style={{ fontSize: '35px', marginTop: '8px', marginLeft: '8px' }} onClick={onSelectClickHandler.bind(null, picture)} />
                        </div>
                        

                        <Row justify="center" align="middle" style={{ height: '100%' }}>
                            <Col>
                                <FullscreenOutlined style={{ fontSize: '35px', width: '100%', marginLeft: 'auto', marginRight: 'auto' }} />
                                <p className="titletext-h2-standard" style={{ color: 'white', margin: "0" }}>Vorschau</p>
                            </Col>
                        </Row>
                        
                    </span>

                }
            </div>
        )
    };

    return (
        <DndProvider backend={HTML5Backend}>
       
            <Row >
                <Image.PreviewGroup>
                    {part._pictures && part._pictures.map(picture => {
                        if(picture.deleted === false)
                        {
                        return (
                            <Col style={{ width: width + "px", height: height + "px", position: "relative", margin: "8px" }}>
                                {selectedPicturesRef.current &&
                                <ImageDnd
                                    selectState={selectState}
                                    pictureSelected={picture.selected}
                                    selectedPictureRef={selectedPicturesRef}

                                    style={{ width: 200 }}

                                    pictureId={picture._id}
                                    projectSrc={project}
                                    partSrc={part}
                                    fileNameSrc={picture.fileName}

                                    imageOverlay={imageOverlay(picture)}
                                    onSelectClick={onSelectClickHandler.bind(null, picture)}
                                    removeImages={moveImagesDnD}
                                />
                                }
                            </Col>
                        )
                    }})}
                    {(!imagesDropped || imagesDropped.length ===0) &&
                    <Col ref={drop} style={{backgroundColor, margin:"8px",height:height, width:width, borderRadius:"20px", border:"2px dashed grey", justifyContent:"center", alignItems:"center", display:"flex"}}>
                        
                        <DownloadOutlined className="ColorGrey" style={{fontSize:"100px"}}/>
                    </Col>
            }
                </Image.PreviewGroup>
            </Row>
            
            {imagesDropped.length >0 &&
            <Col style={{backgroundColor:"rgba(211, 211, 211, 0.5)", borderRadius:"20px", border:"2px solid grey", padding:"8px", position:"relative"}}>
                <Row>             
                {imagesDropped.map((image, index) => (
                <Col style={{ position:"relative",margin: "8px", width: width }}>
                
                    <img key={index} src={URL.createObjectURL(image)} alt="" style={{width: "100%", height: height}} />
                    <div>
                        <Progress percent={uploadProgress[image.name]? uploadProgress[image.name]:0}></Progress>
                    </div>                      
                  
                </Col>
                ))}
                   <Col>
                    <div ref={drop} style={{backgroundColor, margin:"8px",width:"200px", height:"200px", border:"2px dashed grey", borderRadius:"10px",  justifyContent:"center", alignItems:"center", display:"flex"}}>
                    <DownloadOutlined className="ColorGrey" style={{fontSize:"100px"}}/>
                    </div>
                    
                </Col>
                </Row>
                <Row style={{margin:"8px"}} justify="center">
                    <Button onClick={uploadAllDroppedImages}> Hochladen</Button>
                    <Button onClick={removeAllDroppedImages}> Alle Entfernen</Button>
                </Row>
            </Col>
            }
        </DndProvider>
        )
    }

export default ImageGallery