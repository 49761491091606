import { Divider } from 'antd';
import React, { useEffect } from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import './CityList.less'
import ScrollingLock from 'react-scrolling-lock';


const CityList = props => {

    useEffect(() => {
        console.log("listrender");
    }, [props.cities]);


    const handleClick = async (onClick, city) => {

        onClick(city);
    }

    const ComposedList =ScrollingLock()(()=>{
        return (
        <div className="custom-scroll-window" alwaysShowTracks={false} style={{width:"19vw",overflow:"auto", height:"calc(100vh - 260px)"}}>
            {props.cities.map(city => {
                return (
                    <div>
                        <div className="cityList-cityItem" key={city.Name} id={city.Name} onClick={handleClick.bind(null, props.onCitySelected, city.Name)}>
                            <p className="smalltext-bold-standard">{city.Name}</p>
                            <p className="smalltext-standard-lightgray">{city.ProjectCount} {city.ProjectCount == 1 ? "Projekt" : "Projekte"}</p>
                        </div>
                        <Divider style={{margin:"4px"}}></Divider>
                    </div>
                );
            })}

        </div>
        )
    });

    return (
        <ComposedList></ComposedList>
    );
};

export default CityList; 