import React,{useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import { Form, Button, Input, Spin, Alert } from 'antd';
import{UserOutlined, LockOutlined} from '@ant-design/icons'
import{useLazyQuery, gql} from '@apollo/client';
import './PasswordReset.less';
import { data } from 'jquery';

const PasswordResetPage = (props) =>{
 
  const [form] = Form.useForm();
  const {token} = useParams();
  const [errorStateInit, seterrorStateInit]=useState();
  const [SuccessStateInit, setSuccessStateInit]=useState();
  const [errorState, setErrorState]=useState();
  const [SuccessState, setSuccessState]=useState();
  const [tokenValidState, setTokenValidState]= useState(false);

  const requestBodyResetInit = gql `
       query PasswordResetInit($email: String!) {
          passwordResetInit(email: $email) 
     }`;

  const requestBodyReset = gql `
      query PasswordReset($token: String! $password:String!) {
        passwordReset(token: $token, password:$password) 
      }`;
  
  const requestBodyCheckToken = gql `
      query CheckResetToken($token: String!) {
        checkResetToken(token: $token) {
          valid
          token
        }
      }`;

  const [checkTokenRequest, {loading:loadingCkeckToken, error: errorCheckToken, data:dataCheckToken}] = useLazyQuery(requestBodyCheckToken);
  const [passwordResetInitRequest,{loading:loadingInitReset, error:errorInitReset, data:dataInitReset}] = useLazyQuery(requestBodyResetInit);
  const [passwordResetRequest,{loading:loadingReset, error:errorReset, data:dataReset}] = useLazyQuery(requestBodyReset);

  useEffect(() => {
    if(token && !dataCheckToken)
    {
      const vars={};
      vars["token"]=token;
      checkTokenRequest({variables:vars});
    }
    if(dataCheckToken)
    {
      setTokenValidState(dataCheckToken.checkResetToken.valid)
    }
  },[dataCheckToken]);

  const submitPasswortResetInit = async ()=>{
    const vars={};
    vars["email"] = form.getFieldValue("email");

    await passwordResetInitRequest({variables:vars});
    if(dataInitReset && dataInitReset.passwordResetInit)
    {
      seterrorStateInit(undefined);
      setSuccessStateInit("E-Mail zum Zurücksetzen gesendet")
    }
    else{
      seterrorStateInit("Es konnte keine E-Mail zum Zurücksetzen gesendet werden")
    }
  };

  const submitPasswordReset = async()=>{
    var password = form.getFieldValue("password");
    var password2 = form.getFieldValue("password2");
    if(password!==password2)
    {
      setErrorState("Passwörter stimmen nicht überein");
      return;
    }
    const vars={};
    vars["password"] = password;
    vars["token"] = token;

    passwordResetRequest({variables:vars});
  };

  const formItemLayout ={
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  };
 
  return (
    <span className="passwordreset-pagecontainer">
      {!token && (
        <Form className="passwordreset-form" form={form}>
          <h1>Anfrage zum Passwort zurücksetzen</h1>
          {errorInitReset && 
          <Form.Item><Alert message={"Fehler bei der Anfrage zum Zurücksetzen des Passwortes"} type="error"></Alert></Form.Item>
          }
          {dataInitReset && dataInitReset.passwordResetInit &&
          <Form.Item><Alert message={"E-Mail zum Zurücksetzen wurde gesendet"} type="success"></Alert></Form.Item>
          }
          <Form.Item name="email" rules={[{ required: true, message: 'Bitte geben Sie Ihre E-Mail ein!' }]}>
            <Input
              prefix={<UserOutlined type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="E-Mail"
              type="email"
            />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="passwordreset-form-button" onClick={submitPasswortResetInit.bind(null)} >
              Bestätigen
            </Button>
          </Form.Item>
        </Form>
      )}
      {loadingCkeckToken && <Spin></Spin>}
      {dataCheckToken &&(
        <React.Fragment>
          {dataCheckToken.checkResetToken.valid ?(
            <Form className="passwordreset-form" form={form}>
              <h1>Passwort zurücksetzen</h1>
              {errorReset &&
                <Form.Item><Alert message={"Fehler beim zurücksetzen des Passwortes"} type="error"></Alert></Form.Item>
              }
              {dataReset && dataReset.passwordReset &&
                <Form.Item><Alert message={"Passwort erfolgreich zurückgesetzt"} type="success"></Alert></Form.Item>
              }
            <Form.Item name="password" rules={[{ required: true, message: 'Bitte geben Sie Ihr Passwort ein!' }]}>
                <Input
                  prefix={<LockOutlined type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Passwort"
                />
            </Form.Item>
            <Form.Item name="password2" rules={[{ required: true, message: 'Bitte geben Sie Ihr Passwort erneut ein!' }]}>
                <Input
                  prefix={<LockOutlined type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Passwort wiederholen"
                />
            </Form.Item>
            <Form.Item>
            <Button type="primary" htmlType="submit" className="passwordreset-form-button" onClick={submitPasswordReset.bind(null)} >
                  Bestätigen
                </Button>
              </Form.Item>
            </Form>
          ):(
            <Form className="passwordreset-form" form={form}>
            <Form.Item><Alert message={"Der eingegebene Link ist nicht gültig"} type="error"></Alert></Form.Item>          
          </Form>
          )}
        </React.Fragment>
      )}
    </span>
  );
};

export default PasswordResetPage;
